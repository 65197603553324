
.create-meal-dish-list::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.create-meal-dish-list {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.create-meal-dish-list{
    width:'100%';
    margin:2;
    max-height:60vh;
    overflow-y: visible;
}

