.fc { /* the calendar root */
    width: 75%;
  }
.fc .fc-daygrid-day.fc-day-today{
    background: #FD8207;
    border: 1px solid #FD8207;
    color: #fff;
}
.fc .fc-daygrid-day-top{
    flex-direction: row;
    font-weight: bold;
    margin-bottom: 0px;
}
.fc-col-header-cell.fc-day{
    background: #007aff;
    color: #fff;
    text-align: left;
    /* text-transform: uppercase; */
    padding: 0 5px;
    /* font-size: 15px; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px 
}
.fc .fc-daygrid-day-frame{
    padding: 5px;
    /* min-height: 145px; */
}
/* .fc-theme-standard td{
    background: #fff;
} */
.fc table{
    border-spacing: 3px;
    border-collapse: separate;
}
.fc-daygrid-day {
    background: rgba(255, 255, 255, 0.5)!important;;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4) !important;
}
.fc-theme-standard .fc-scrollgrid{
    border: none;
}
.fc-theme-standard td, .fc-theme-standard th{
    border: none;
}
/* .fc-daygrid-day-bg{
    display: none;
} */
.fc-daygrid-day-frame:before, .fc-daygrid-day-events:before, .fc-daygrid-event-harness:before{
    display: none;
}
.fc-daygrid-day-frame:after, .fc-daygrid-day-events:after, .fc-daygrid-event-harness:after{
    display: none;
}
.fc .fc-daygrid-day-bottom{
    display: none;
}

.fc-daygrid-day.fc-selected-date {
    background: lightgray;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 0%;
    left: 50%;
    margin-left: -60px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .fc-button.fc-today-button{
    background: rgba(255, 255, 255, 0.5)!important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    width: 60px!important;
  }

  .fc-button-group{
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
  }

  .fc-button.fc-button-primary.fc-button-active {
    padding: 10px;
    border-radius: 10px!important;
    margin: 4px;
    width: 60px!important;
    backdrop-filter: blur(10px);
    background: #000000;
  }

  .fc-button.fc-button-primary {
    padding: 10px;
    background: transparent;
    margin: 4px;
    border: none;
    min-width: 60px!important;
    color: #000;    
  }

  .fc-button.fc-button-primary:hover {
    padding: 10px;

    background: #00000082;
    /* min-width: 60px!important; */
    border-radius: 10px!important;    
  }

  .fc-toolbar-title {
    font-size: 20px!important;
  }
