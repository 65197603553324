.journal-date-picker {
    padding-top: 2px;
    padding-left: 10px;
    
}

.journal-date-picker .MuiOutlinedInput-notchedOutline{
    border: none;
}


.journal-date-picker .MuiOutlinedInput-input{
    padding: 0;
    width: 60px;
    cursor: pointer!important; 
}

.journal-date-container > fieldset{
    border: none;
}


.weight-log-data .stepper-input{
    background: white;
    border: 1px solid #ddd;
    border-radius: 40px;
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    width: 180px;
    height: 50px;
    transform: translate(-50%, -50%);
}

.weight-log-data .stepper-input input{
    display: none;
}

.weight-log-data .stepper-input .input{
    display: flex;
    width: 100%;
    height: 100%;
}

.weight-log-data .stepper-input .input button{
    width: 50px;
    height: 100%;
    border-radius: 10px;
    background : #fff;
    cursor:pointer;
    border:none;
    color:#222; 
}

.weight-log-data .stepper-input .input button active{
    background: #555;
}

.weight-log-data .stepper-input .input .range{
    flex: 1;
    text-align: center;
    overflow: hidden;
}

.weight-log-data .stepper-input .input .range .list{
    transition : all 300ms ease-in-out;
}

.weight-log-data .stepper-input .input .range .list span{
    display: block;
    width: 100%;
    height: 50px;
    color: #222;
    font-size: 10px;
}


.external-meal-form-field{
    width: 45%;
    padding:10px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgb(20 17 36 / 24%);
}


.external-meal-form-field:focus-visible{
    border: none;
}