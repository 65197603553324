.plan_list ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    width: 80%;
    height:350px;
    overflow-x: scroll;
    scroll-behavior: smooth;
}
.plan_list ul::-webkit-scrollbar {
    background: transparent; /* make scrollbar transparent */
    -webkit-appearance: none;
    width: 0;
    height: 0;
}
.plan_list li{
    /* background:#FF61F6; */
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    margin: 0 4px;
    white-space: nowrap;
} 

.plan_list button{
    height: 50px;
    width: 50px;
    background-color: transparent;
    border: none;
}

.plan_list .cardFront.selected{
    box-shadow: rgb(64 186 56) 1px 1px 15px !important;
    
}