
.meal-plan-card{
    width: 31%;
    height: 320px;
    background: transparent!important;
}

@media only screen and (max-width: 767px) {
    .meal-plan-card{
        width: 100%!important;
        height: 320px;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .meal-plan-card{
        width: 48%!important;
        height: 320px;
    }    
}

