.dish-recipe-form-field{
    width: 85%;
    padding:10px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgb(20 17 36 / 24%);
}


.dish-recipe-form-field:focus-visible{
    border: none;
}