.meal-calendar .fc-view{
    background: rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(10px);
	/* border: 1px solid rgba(255, 255, 255, 0.4); */
}

.meal-calendar .fc-theme-standard td{
    /* border: none!important;; */
	/* border: 1px solid rgba(255, 255, 255, 0.4) !important; */
}

.meal-calendar .fc-theme-standard th{
	/* border: 1px solid rgba(255, 255, 255, 0.4) !important; */
    /* height:40px; */
}

.meal-calendar .fc-theme-standard .fc-scrollgrid {
    /* border: none!important; */
}

.meal-calendar .fc-scroller{
    /* overflow: unset !important; */
}

.meal-calendar .event-card{
    border-radius: 0;
    padding:4px;
    margin:0.5px;
    font-size:0.7rem;
    text-overflow: ellipsis;
}

.meal-calendar .fc-daygrid-event:hover{
    background: rgb(255 255 255 / 30%);
}