.react-datepicker__tab-loop{
    margin: 0!important;
}

.react-datepicker__input-container input{
    height: 56px;
    width:100%;
    padding:10px;
    font-size: 14px;
    /* box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff; */
    background: transparent;
    padding: 0.4375rem 0.75rem;
    border: 0;
    outline: 1px solid #eeeef0;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
  
.datepicker__title {
    position: absolute;
    top: 27px;
    margin-left: 20px;
    font-weight: 400;
} 


.subscription-details-container{
    /* background: #f3f3f3; */
}

.subscription-details-item{
    border-radius: 0.3em;
    background-color: white;
    height:30px;
    /* margin-bottom: 20px; */
    -moz-box-shadow: 0 0 3px grey;
    -webkit-box-shadow: 0 0 3px grey;
    box-shadow: 0 0 5px grey;
}

.autocomplete-suggestion{
    cursor:pointer;
    background:white;
    list-style: none;
    padding:2px;
}

.autocomplete-suggestion li{
    padding:2px;
}