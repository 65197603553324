html{
	position: fixed;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 4px;
}

::-webkit-scrollbar
{
	width: 1px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 4px;
	background-image: -webkit-gradient(linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(122,153,217)),
        color-stop(0.72, rgb(73,125,189)),
        color-stop(0.86, rgb(28,58,148)));
}

.glass-box{
	/* background: rgba(255, 255, 255, 0.5); */
	background: #ffffff;
	/* border-radius: 15px; */
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	/* border: 1px solid rgba(255, 255, 255, 0.4); */
}

.glass-box-inner{
	/* border-radius: 50px;
	background: #ffffff5e;
	box-shadow:  -5px -5px 10px #ffffffee,
             5px 5px 10px #ffffff; */
			 /* box-shadow:  -5px -5px 1px #ffffffee,
             5px 5px 10px #ffffffee; */
}

.hidden{
	display: none;
}

.role-list-container{
	list-style-type:  none;;
	background: '#fff';
	width:'100%';
	box-shadow:0px 3px 1px -2px rgb(145 158 171 / 20%), 
		0px 2px 2px 0px rgb(145 158 171 / 14%),
	 	0px 1px 5px 0px rgb(145 158 171 / 12%);
}

.role-list-item:hover{
	background-color: #b4b4b4 ;
}

.rdrDefinedRangesWrapper{
	display:none;
}

.MuiDrawer-paper{
	/* background: rgb(255 255 255 / 18%); */
	box-shadow: none;
}
.MuiModal-backdrop{
	background: rgba(0, 0, 0, 0.1);
}

.MuiDrawer-paper .MuiListItemButton-root.active{
	background: rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(10px);
	border: 1px solid rgba(255, 255, 255, 0.4);
}

body{
	/* background: transparent linear-gradient(90deg, #B6F8DC 0%, #50C4F5 100%) 0% 0% no-repeat; */
	background : url('../img/login_bg.png');
	backdrop-filter: blur(40px);
}

.w-100{
	width:100%;
}

.MuiPickersToolbar-penIconButton{
	display:none!important;
}