.partner-client-report .alice-carousel__stage-item{
    margin: 4px !important;
    width: 345px !important;
    padding: 2px;
}

.swiper-container {
    /* width: 100%; */
    /* display:flex;
    justify-content: start; */
}

.swiper-wrapper {
    /* display:flex; */
    /* transform: none!important; */
}

.swiper-slide {
    padding-left:25px;
    padding-right:25px;
    padding-bottom:50px;
    padding-top: 1px;
    /* background-position: center; */
    /* background-size: cover; */
    /* width: 250px!important; */
    /* height: 300px; */
}
  